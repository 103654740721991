import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, FormControl, Tooltip, Grid, InputLabel, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/LOGO ONCENTER/iconLogoOncenter.png'
import bg from '../../assets/images/background.jpg'
import { FaPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom'
import { getData } from './service';
import { deleteCookie } from 'utils/setCookie';
import { useTranslation } from 'react-i18next';
import './media/css/home.scss'

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [getDataBusiness, setGetDataBusiness] = useState([]);
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        const dataDomain = await getData();
        setGetDataBusiness(dataDomain.data.data);
  
        const timer = setTimeout(() => {
          setLoading(false);
        }, 2000);
  
        return () => clearTimeout(timer);
      } catch (error) {
         setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const firstName = localStorage.getItem('first_name')
  const lastName = localStorage.getItem('last_name')
  
  const handleLogout = () => {
    localStorage.clear()
    deleteCookie('lmsAuth');
    window.location.href = '/login'
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    setSelectedLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }

  const truncateString = (str, numWords) => {
    const words = str.split(' ');
    if (words.length > numWords) {
      return words.slice(0, numWords).join(' ') + '...';
    }
    return str;
  };

  return (
    <Box className="main__home" sx={{ width: '100%', maxWidth: '900px', margin: '0 auto', height: '100vh' }}>
      <Stack direction="row" alignItems="center" gap={2} justifyContent="right">
        <div className='languages'>
          <Box sx={{ minWidth: 145 }}>
              <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-label">{t('choose_languages')}</InputLabel>
                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedLanguage}
                  onChange={changeLanguage}
                  >
                  <MenuItem value="jp">Japanese</MenuItem>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value='vn'>Vietnamese</MenuItem>
                  </Select>
              </FormControl>
          </Box>
        </div>
        <Stack direction="row" gap={2} alignItems='center' justifyContent="right" mt={2}>
          <Avatar>
            {firstName.charAt(0)}
          </Avatar>
          <Box>
            <Typography variant='body1'>{lastName} {firstName}</Typography>
            <Typography onClick={handleLogout} variant='body2' sx={{ color: '#333', opacity: '0.5', cursor: 'pointer', transition: '0.2s ease-in', '&:hover': {opacity: '0.8'} }}>{t('log_out')}</Typography>
          </Box>
        </Stack>
      </Stack>
      <Typography variant='h4' textAlign='center' mt={2} mb={2}>{t('list')}</Typography>
      <Link to='/config'>
        <Button startIcon={<FaPlus />} 
                sx={{ 
                  background: '#2e3192',
                  mt: 2,
                  mb: 2,
                  float: 'right',
                  width: "120px",
                  height: '53px',
                  color: 'white',
                  transition: '0.2s ease-in', 
                  boxShadow: 'unset', 
                  '&:hover': {backgroundColor: 'rgba(46, 49, 146, 0.8)'} 
                  }}
                >
                    
          {t('create')}
        </Button>
      </Link>
      <Box>
        <Grid container spacing={2}>
          {getDataBusiness.map((data, index) => (
            <Grid key={index} item xs={12} sm={4}>
              <Card sx={{ maxWidth: 345, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height: '100%', position: 'relative', transition: '0.2s ease-in', '&:hover': {backgroundColor: 'rgb(243 236 236 / 60%)'} }}>
                {loading ? (
                  <Skeleton variant="rectangular" sx={{ height: 250 }} />
                ) : (
                  <CardMedia
                    sx={{ height: 200, opacity: '0.8' }}
                    image={bg}
                    title="green iguana"
                  />
                )}
                <CardContent>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1} mb={2}>
                      <Tooltip title={data.company_name}>
                        <Typography gutterBottom variant="h5" component="div">
                          {truncateString(data.company_name, 3)}
                        </Typography>
                      </Tooltip>
                      <img src={data.logo} alt="" width={40} style={{ borderRadius: '5px' }}/>
                    </Stack>
                  )}
                 <Box sx={{ height: '100px' }}>
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <Tooltip title={data.company_description}>
                      <Typography variant="body1" color="text.secondary" mt={1} mb={1}>
                        {truncateString(data.company_description, 5)}
                      </Typography>
                    </Tooltip>
                  )}

                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    <Typography variant="body2" color="text.secondary" mt={1} mb={1}>
                      {t('user_access_is')}<strong style={{ color: "#333" }}>{`https://${data.domain}.oncenter.vn`}</strong>
                    </Typography>
                  )}
                </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'right' }}>
                {loading ? (
                  <>
                    <Skeleton variant="rectangular" width={100} height={40} />
                  </>
                ) : (
                  <>
                    {/* <Button sx={{ color: '#333', fontWeight: 550 }}>
                      Chỉnh sửa
                    </Button> */}
                    <Link to={`https://${data.domain}.oncenter.vn`}>
                      <Button sx={{
                        width: '100px',
                        height: '40px',
                        float: 'right',
                        backgroundColor: '#2e3192',
                        color: 'white',
                        fontWeight: 550,
                        transition: '0.2s ease-in',
                        boxShadow: 'unset',
                        '&:hover': { backgroundColor: 'rgba(46, 49, 146, 0.8)' }
                      }}>
                        {t('access')}
                      </Button>
                    </Link>
                  </>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default Home
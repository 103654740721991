import React, { FC, useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Stack, Divider, Avatar, FormControlLabel, Checkbox, CircularProgress, Snackbar, Alert  } from '@mui/material';
import { checkAuthentication, registerApi } from './service/index'
import { useNavigate } from 'react-router-dom';
import './media/register.scss'
import { deleteCookie, hasCookie, setCookie } from 'utils/setCookie';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/LOGO ONCENTER/iconLogoOncenter.png'
import logoSecond from '../../assets/images/LOGO ONCENTER/LogoOncenter stroke ko TEXT-ngang.png'
import google from '../../assets/images/social/google.png';
import facebook from '../../assets/images/social/facebook.png';
import github from '../../assets/images/social/github.png';
import createName from '../../assets/images/items/createName.jpg'
import createLogo from '../../assets/images/items/createPage.jpg'
import createURL from '../../assets/images/items/createURL.jpg'
import createColor from '../../assets/images/items/createColor.jpg'

import Slider from "react-slick";
import { FaArrowLeft } from 'react-icons/fa';

const Register: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [last_name, setLastName] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [first_name, setFirstName] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [open, setOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openFailSnackbar, setOpenFailSnackbar] = useState(false);
  const [failMessage, setFailMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

 const navigate = useNavigate();

 useEffect(() => {
  const isAuthenticated = checkAuthentication();
  if(isAuthenticated) {
    deleteCookie('lmsAuth')
  } 
}, []);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const success = await registerApi(email, password, last_name, first_name, authCode);
      if (success.status) {
        setCookie('lmsAuth', success.data.authCode )
        setLoading(false)
        setTimeout(() => {
          if(hasCookie('lmsAuth')) {
            navigate('/config')
          }
        }, 3000);
        setLoading(true)
        
      } else {
        alert("Sai")
      }
    } catch (error) {
      console.error("Error:", error.message);
      const errorMessage = error.response.data.message
      if (errorMessage === "Not have Data") {
        handleFail('All fields must not be left blank!')
      } else if (errorMessage === "E-mail exist") {
        handleFail('Email is already in use.')
      }
      // if (error.response && error.response.data && error.response.data.message) {
      //   const errorMessages = error.response.data.message;
      //   const errorArray = Array.isArray(errorMessages) ? errorMessages : [errorMessages];
      //   errorArray.forEach(errorMessage => {
      //     if (errorMessage === "email should not be empty") {
      //       setEmailError("Không được bỏ trống");
      //     } else if (errorMessage === "email must be an email") {
      //       setEmailError("Trường này phải là dạng Email (example@gmail.com)");
      //     } else if (errorMessage === "password should not be empty") {
      //       setPasswordError("Không được bỏ trống");
      //     } else if (errorMessage === "first_name should not be empty") {
      //       setFirstNameError("Không được bỏ trống");
      //     } else if (errorMessage === "last_name should not be empty") {
      //       setLastNameError("Không được bỏ trống");
      //     }
      //   });
      // }
      setLoading(false);
      }
    }
    
    var settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      waitForAnimate: false,
      arrows: false, 
      autoplay: true, 
      autoplaySpeed: 3000 
    };

    const handleClick = () => {
      setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleOpenSnackbar = (message) => {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
    };
  
    const handleCloseFailSnackbar = () => {
      setOpenFailSnackbar(false);
    };
  
    const handleOpenFailSnackbar = (message) => {
      setFailMessage(message);
      setOpenFailSnackbar(true);
    };
  
    const handleFail = (message) => {
      handleOpenFailSnackbar(message);
    };
  

  return (
    <div>

      <div className="register_main">
        <Box className="box__register">
          <Stack direction="row">
            <Box className="register__box-left" sx={{ width: '450px', padding: 12, borderRadius: '3px' }}>
              <Box textAlign="center">
                <img src={logoSecond} alt="logo" width={150} />
                <Typography variant='h6' fontWeight={550} mt={2} mb={2}>Sign Up</Typography>
              </Box>
                <form onSubmit={handleSubmit}>
                  <Stack direction='row' gap={1}>
                    <TextField
                    label="Last name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={last_name}
                    error={errorMessage !== ''}
                      helperText={lastNameError}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      if (lastNameError) setLastNameError('')
                    }}
                    />
                    <TextField
                      label="First name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!firstNameError}
                      helperText={firstNameError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={first_name}
                      onChange={(e) => {
                        setFirstName(e.target.value)
                      }}
                    />
                  </Stack>
                  <TextField
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!emailError}
                    helperText={emailError}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={email}
                    onChange={(e) =>{
                      setEmail(e.target.value)
                      if (emailError) setEmailError('')
                    }}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={!!passwordError}
                    helperText={passwordError}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                      if (passwordError) setPasswordError('')
                    }}
                  
                  />
                  
                  <Stack direction="row" alignItems="center">
                    <Checkbox defaultChecked  size="small" />
                    <Typography variant='body2'>
                      I agree with <strong>Oncenter's</strong> <a href='#'>Terms of Service</a>
                    </Typography>

                  </Stack>
                  
                  {loading ? (
                    <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 2, mb: 2 }} />
                  ) : (
                    <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }}>
                        <Link to="/login">
                          <Button className='redirect__button' sx={{ height: '55px', backgroundColor: '#2e3192', borderTopRightRadius: 'unset', borderBottomRightRadius: 'unset', transition: '0.2s ease-in', boxShadow: 'unset', '&:hover': {backgroundColor: 'rgba(46, 49, 146, 0.8)'} }}>
                            <FaArrowLeft style={{ color: 'white', fontWeight: '550' }}/>
                          </Button>
                        </Link>
                        <Button
                        
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{ width: '100%', height: '55px', borderRadius: '2px', fontWeight: 550, mt: 2, mb: 2, backgroundColor: '#2e3192', borderTopLeftRadius: 'unset', borderBottomLeftRadius: 'unset', transition: '0.2s ease-in', boxShadow: 'unset', '&:hover': {backgroundColor: 'rgba(46, 49, 146, 0.8)'} }}
                          onClick={handleSubmit}
                          
                        >
                          Register
                        </Button>
                      </Box>
                  )}
                </form>
                <Divider></Divider>
                {/* <Box mt={2}>
                  <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                    <a className='social' href='#' onClick={handleClick}><img alt="logo" src={google} width={30}/></a>
                    <a className='social' href='#' onClick={handleClick}><img alt="logo" src={facebook} width={30}/></a>
                    <a className='social' href='#' onClick={handleClick}><img alt="logo" src={github} width={30}/></a>
                  </Stack>
                </Box> */}
                <Typography variant='body2' ml={5} mt={4}>Already have an account? <Link to='/login'>Sign in!</Link></Typography>
                  
            </Box>
            <Box className="register__box-right" sx={{ width: '350px', backgroundColor: '#0047AB', borderRadius: '3px' }} padding={12}>
                  <Box color="white" sx={{ padding: '25px', marginTop: '60px' }}>
                    <Slider {...settings}>
                      <Box textAlign="center">
                        <img src={createName} alt="create name"  width={300} style={{ borderRadius: '7px', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 5px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px' }}/>
                        <Typography variant='body1' fontWeight={550} mt={5} mb={2}>Create and Customize Company Name</Typography>
                        <Typography variant='body2' mt={2} mb={2}>This website allows you to create and customize the adjustment of the company name directly on the website you are using!</Typography>
                      </Box>
                      <Box textAlign="center">
                        <img src={createURL} alt="create name"  width={300} style={{ borderRadius: '7px', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 5px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px' }}/>
                        <Typography variant='body1' fontWeight={550} mt={5} mb={2}>Initialize Domain Paths</Typography>
                        <Typography variant='body2' mt={2} mb={2}>This website can initialize one or more domains for a user!</Typography>
                      </Box>
                      <Box textAlign="center">
                        <img src={createLogo} alt="create name"  width={300} style={{ borderRadius: '7px', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 5px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px' }}/>
                        <Typography variant='body1' fontWeight={550} mt={5} mb={2}>Create and Customize Brand Logo</Typography>
                        <Typography variant='body2' mt={2} mb={2}>This website can create and customize logos according to your preferences!</Typography>
                      </Box>
                      <Box textAlign="center">
                        <img src={createColor} alt="create name"  width={300} style={{ borderRadius: '7px', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 5px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px' }}/>
                        <Typography variant='body1' fontWeight={550} mt={5} mb={2}>Create and Customize Colors</Typography>
                        <Typography variant='body2' mt={2} mb={2}>This website can create and customize colors for the website!</Typography>
                      </Box>
                    </Slider>
                  </Box>
            </Box>
          </Stack>
        </Box>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          severity="warning"
          variant="filled"
          sx={{ width: '100%', color: '#fff' }}
        >
          Hiện tại chức năng đang được phát triển!!!
        </Alert>
      </Snackbar>
      <Snackbar open={openFailSnackbar} autoHideDuration={3000} onClose={handleCloseFailSnackbar}>
        <Alert
          onClose={handleCloseFailSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: '100%', color: '#fff' }}
        >
          {failMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Register;

import { Alert, Box, Button, CircularProgress, FormControl, FormHelperText, InputAdornment, ListItem, ListItemText, Paper, Snackbar, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SketchPicker  } from 'react-color';
import './media/config.scss'

import { VisuallyHiddenInput } from 'components/Styled';
import { FaArrowLeft, FaUpload } from "react-icons/fa";

import { uploadApi, uploadAvatar } from './service';
import { MdCheckCircleOutline } from 'react-icons/md';
import { getCookie } from 'utils/setCookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const Config: FC = () => {
  const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);
    const [selectedColor, setSelectedColor] = useState<string>('');

    const [logo, setLogo] = useState<string>(""); 
    const [uploadSuccess, setUploadSuccess] = useState(false); 
    const [uploadError, setUploadError] = useState<Error | null>(null); 
    const [name, setName] = useState<string>(''); 
    const [color, setColor] = useState<string>('');
    const [subDomain, setSubDomain] = useState<string>(''); 
    const [description, setDescription] = useState<string>('');
    const [loadingAvatar, setLoadingAvatar] = useState(false);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);

    
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openFailSnackbar, setOpenFailSnackbar] = useState(false);
    const [failMessage, setFailMessage] = useState('');

    const handleColorChange = (color) => {
      setSelectedColor(color.hex);
      setColor(color.hex); // Update the color field with the selected color
    document.documentElement.style.setProperty('--primary-color', color.hex);
    };
  
    const steps = [
      {
        label: `${t('change_brand_logo')}`,
      },
      {
        label: `${t('change_domain')}`,
      },
      {
        label: `${t('change_company_name')}`,
      },
      {
        label: `${t('change_description')}`,
      },
      {
          label: `${t('change_color')}`,
      },
    ];

    const handleUpload = async () => {
      try {
        if (!logo || !name || !color || !subDomain || !description) {
          handleFail(`${t('message_alert_blank')}`)
          return;
        }
  
        const formData = new FormData();
        formData.append('company_name', name);
        formData.append('color', color);
        formData.append('company_description', description)
        formData.append('domain', subDomain);
        formData.append('logo', logo); // Append the logo file
        const headers = {
          'X-Authorization': `${getCookie('lmsAuth')}`,
          "Content-Type": "application/json"
        };
        setLoading(true);
        const response = await uploadApi(name, logo, color, subDomain, description, headers); 
          if(response.data.status) {
            setTimeout(() => {
              setLoading(false);
              sessionStorage.setItem("domain", response.data.data.domain)
              navigate('/congratulation');
            }, 2000);
          }
        setUploadSuccess(true);
      } catch (error) {
        setUploadError(error);
        const errorMessage = error.response.data.error
        if (errorMessage === "Tên miền đã tồn tại, vui lòng sử dụng tên miền khác!") {
          handleFail(`${t('message_alert_exist')}`)
        }
      }
    };

    const handleFileChangeAndUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setLoadingAvatar(true);
        const files = event.target.files;
        if (files && files.length > 0) {
          const file = files[0];
          const headers = {
            'X-Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQ1Nzc3MDAsImRhdGEiOnsiX2lkIjoiNjU4MjVhYTQ5ZDY2YWM4YzQ3YzZiMDFkIiwia2V5IjoiOTNiOTgxN2VkZmQ1ZjU1NDBkZTI2MDNiM2M3N2JlZmEiLCJzaWduYXR1cmUiOiI2ZGI5M2RhMzE1YzRjNzBkNjY2YjNiNWRjZjIwYzUzMiIsInNlc3Npb24iOiI2NTgyNWFhNDlkNjZhYzhjNDdjNmIwMWYifSwiaWF0IjoxNzAzMDQxNzAwfQ.R0CrGfSMvi_V3T445vlp75Eetz_x6RHCrWtaSkXo0A8'
          };
          const imageUrl = await uploadAvatar(file, headers);
          setLogo(imageUrl);
          setUploadSuccess(false);
          setUploadError(null);
        }
      } catch (error) {
        console.error('Upload failed:', error);
      } finally {
        setLoadingAvatar(false);
      }
    };
    

    const handleColorSelect = (color: string): void => {
      setSelectedColor(color);
      setColor(color); // Update the color field with the selected color
      document.documentElement.style.setProperty('--primary-color', color);
    };

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    const handleOpenSnackbar = (message) => {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
    };
  
    const handleCloseFailSnackbar = () => {
      setOpenFailSnackbar(false);
    };
  
    const handleOpenFailSnackbar = (message) => {
      setFailMessage(message);
      setOpenFailSnackbar(true);
    };
  
    const handleFail = (message) => {
      handleOpenFailSnackbar(message);
    };
  


  return (
    <Box>
      <Box mt={2} mb={2} ml={2}>
        <Link to="/home" style={{ textDecoration: 'none', color: '#333', fontWeight: '550' }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <FaArrowLeft />
            <Typography variant='body1'>{t('back_to')}</Typography>
          </Stack>
        </Link>
      </Box>

      <Box sx={{ maxWidth: 800, margin: "0 auto" }}>
        
          <Typography variant="h5">{t('custom')}</Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
              <Step key={step.label}>
                  <StepLabel
                  optional={
                      index === 4 ? (
                      <Typography variant="caption">{t('last_step')}</Typography>
                      ) : null
                  }
                  >
                  {step.label}
                  </StepLabel>
                  <StepContent>
                      {index === 0 &&
                        <div>
                          <Stack flexDirection="column" gap={2}>
                            <Button
                              component="label"
                              variant="contained"
                              startIcon={<FaUpload />}
                              sx={{
                                width: '300px',
                                height: '102px',
                                background: 'transparent',
                                color: '#333',
                                fontWeight: 550,
                                border: '1px dashed',
                                marginBottom: 2,
                                '&:hover': {
                                  backgroundColor: 'rgba(0,0,0,0.1)',
                                },
                              }}
                            >
                              {t('upload_brand_logo')}
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleFileChangeAndUpload}
                              />
                            </Button>
                            {loadingAvatar ? (
                              <CircularProgress  />
                            ) : (
                              logo && <img src={logo} alt="Uploaded Avatar" width={200} />
                            )}
                          </Stack>
                        </div>
                      } 
                      {index === 1 && 
                        <FormControl sx={{marginBottom: 2}}>
                          <TextField 
                            value={subDomain}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">https:// </InputAdornment>,
                              endAdornment:<InputAdornment className='weight' position="end" sx={{ fontWeight: '550 !important' }}>.oncenter.vn</InputAdornment>
                            }}
                            onChange={(event) => setSubDomain(event.target.value)} placeholder={`${t('put_domain_name')}`}/>
                          <FormHelperText id="my-helper-text">https://YOUR_URL.com/</FormHelperText>
                        </FormControl>
                      } 
                      {index === 2 && 
                        <FormControl sx={{marginBottom: 2}}>
                          <TextField 
                            value={name}
                            onChange={(event) => setName(event.target.value)} placeholder={`${t('put_company_name')}`}/>
                          <FormHelperText id="my-helper-text">Ex: IKIGROUP</FormHelperText>
                        </FormControl>
                      }
                      {index === 3 && 
                        <FormControl sx={{marginBottom: 2}}>
                        <TextField 
                          value={description}
                          onChange={(event) => setDescription(event.target.value)} placeholder={`${t('put_description')}`}/>
                        <FormHelperText id="my-helper-text">Ex: NOTE</FormHelperText>
                      </FormControl>
                      }
                      {index === 4 && 
                        <Box>
                          <Stack direction="row" spacing={2} mt={2} mb={2}>
                          <SketchPicker
                            color={selectedColor}
                            onChange={handleColorChange}
                          />
                          </Stack>
                          <Typography variant="caption">{t('description_color')}</Typography>
                        </Box>
                      }
                      
                      <Box>
                          <div>
                          {loading ? (
                            <CircularProgress sx={{ ml: 5, padding: 1 }} /> 
                          ) : (
                          <Button
                              className="config_bgButton"
                              onClick={index === steps.length - 1 ? handleUpload : handleNext}
                              sx={{ mt: 1, mr: 1, backgroundColor: `${selectedColor} !important` }}
                          >
                              {index === steps.length - 1 ? `${t('button_finish')}` : `${t('button_next')}`}
                          </Button>
                          )}
                          <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                          >
                              {t('button_comeback')}
                          </Button>
                          </div>
                      </Box>
                  </StepContent>
              </Step>
              ))}
          </Stepper>
          {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button sx={{ mt: 1, mr: 1 }}>
                      Reset
                  </Button>
              </Paper>
          )}
      </Box>
      <Snackbar open={openFailSnackbar} autoHideDuration={3000} onClose={handleCloseFailSnackbar}>
        <Alert
          onClose={handleCloseFailSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: '100%', color: '#fff' }}
        >
          {failMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Config
import axios, { AxiosRequestConfig } from "axios";
import { BusinessData } from "../interface";

const uploadApi = async (
  company_name: string,
  logo: string,
  color: string,
  domain: string,
  company_description: string,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  const requestData: BusinessData = { company_name, color, logo, domain, company_description };
  const url= "https://hr.api.ikigroup.vn/company/new";
  return axios.post(url, requestData, { headers  });
};

const uploadAvatar = async (
  file: File,
  headers: AxiosRequestConfig["headers"]
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('file[]', file);

    const config: AxiosRequestConfig = {
      method: 'post',
      url: 'https://media.exam24h.com/upload-file?callback=https://api.edu-like.exam24h.com/api/chat-media/create',
      headers,
      data: formData
    };

    const response = await axios.request(config);
    if (response.data && response.data[0] && response.data[0].src) {
      return response.data[0].src;
    } else {
      throw new Error('Upload failed: Invalid response');
    }
  } catch (error) {
    throw new Error(`Upload failed: ${error.message}`);
  }
};

export {uploadApi, uploadAvatar}

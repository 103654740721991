import axios from "axios";
import { getCookie } from "utils/setCookie";
import { GetData } from "../interface";



export const getData = async (): Promise<any> => {
    const url = 'https://hr.api.ikigroup.vn/company/list';
    try {
        const axiosConfig = {
            headers: {
                "X-Authorization": getCookie('lmsAuth'),
            },
        };

        return await axios.get(url, axiosConfig);
    } catch (error) {
        throw new Error('Failed to fetch user data');
    }
}
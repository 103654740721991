import axios from "axios";
import { getCookie } from "utils/setCookie";
import { LoginData } from "../interface";

const loginApi = async (
    email: string,
    password: string,
  ): Promise<any> => {
    const requestData: LoginData = { email, password };
    const url= "https://hr.api.ikigroup.vn/auth/login";
    return axios.post(url, requestData);
  };
  
  const checkAuthentication = (): boolean => {
    const isAuthenticated = !!getCookie('lmsAuth');
    return isAuthenticated;
  };
  
  export { loginApi, checkAuthentication };
  
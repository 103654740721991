import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Register from './pages/Register';
import Config from './pages/Config';
import { checkAuthentication } from 'pages/Register/service';
import { navigateTo } from 'utils/navigate';
import NoFound from 'pages/NoFound';
import Congrat from 'pages/Congrat';
import Home from 'pages/Home';
import Login from 'pages/Login';

import './App.css'
import LanguageSelect from 'components/LanguageSelect';
import { useTranslation } from 'react-i18next';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const { t, i18n } = useTranslation();

  const mainRoute = ["/config", "/", "/home"]

  useEffect(() => {
    const isAuthenticated = checkAuthentication();
    setAuthenticated(isAuthenticated);
    if(!isAuthenticated && mainRoute.includes(window.location.pathname)) {
      navigateTo('')
    } 
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    setSelectedLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }

  return (
    <React.Fragment>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Navigate to ='/register' />} />
        <Route path="/congratulation" element={<Congrat />}/>
        <Route path="/config" element={<Config />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/404" element={<NoFound />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;

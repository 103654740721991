import axios from "axios";
import { RegisterData } from "../interface";
import { getCookie } from "utils/setCookie";

const registerApi = async (
  email: string,
  password: string,
  last_name: string,
  first_name: string,
  authCode: string,
): Promise<any> => {
  const requestData: RegisterData = { email, password, last_name, first_name, authCode };
  const url= "https://hr.api.ikigroup.vn/auth/register";
  return axios.post(url, requestData);
};

const checkAuthentication = (): boolean => {
  const isAuthenticated = !!getCookie('lmsAuth');
  return isAuthenticated;
};

export { registerApi, checkAuthentication };

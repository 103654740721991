import React, { useState, useEffect } from 'react'
import { Typography, TextField, Stack, InputAdornment, IconButton, Button  } from '@mui/material'
import './media/css/cgrat.scss'
import { MdOutlineContentCopy } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import successAnimation from './media/images/successAnimation.json'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Congrat = () => {
    const [text, setText] = useState<string>('');
    const [combinedValue, setCombinedValue] = useState<string>('');
    const [animationVisible, setAnimationVisible] = useState(true);
    const [showTypography, setShowTypography] = useState(false);
    const { t, i18n } = useTranslation();
    const [lottieCompleted, setLottieCompleted] = useState(false);
    useEffect(() => {
        const getDomain = sessionStorage.getItem("domain")
        const combined = 'https://' + getDomain + '.oncenter.vn'
        
        setCombinedValue(combined)

         AOS.init();
    }, []);

    useEffect(() => {
      const timer = setTimeout(() => {
        setAnimationVisible(false);
        setShowTypography(true);
      }, 2800);
  
    }, []); 
    

    const redirectToPage = () => {
        window.location.href = combinedValue 
    };

  return (
    <div className="congrat__main">
        <Stack flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: "100vh" }}>
            {animationVisible && (
              <div className="lottie-container" style={{ opacity: 1 }}>
              <Lottie animationData={successAnimation} />
            </div>
              )
            }

            {showTypography && (
              <div data-aos="fade-zoom-in"
              data-aos-duration="1000">
                  <Typography  variant='h5' mb={2}>{t('success_congrat')}</Typography>
                  <Button sx={{ backgroundColor: '#6495ED', color: '#fff', boxShadow:' rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px', '&:hover': { backgroundColor: 'rgba(100, 149, 237, 0.6)' } }} onClick={redirectToPage}>{t('redirect')}</Button>
                  <Typography/>
              </div>
              )
            }
        </Stack>
    </div>
  )
}

export default Congrat